import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import MouseIcon from '@mui/icons-material/Mouse';
import EuroIcon from '@mui/icons-material/Euro';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import HelpIcon from '@mui/icons-material/Help';
import GavelIcon from '@mui/icons-material/Gavel';

import CartagonLogo from '../../img/Cartagon_logo_sm.png'

const drawerWidth = 240;

export default function ClippedDrawer() {

  const navigate = useHistory();

  const drawer_list = [
    {
      title: "Introduction",
      path: "/dev/introduction",
      icon: <InfoIcon/>
    },
    {
      title: "Installation",
      path: "/dev/installation",
      icon: <InstallDesktopIcon/>
    },
    {
      title: "How to use",
      path: "/dev/howtouse",
      icon: <MouseIcon/>
    },
    {
      title: "Pricing",
      path: "/dev/pricing",
      icon: <EuroIcon/>
    },
    {
      title: "Privacy",
      path: "/dev/privacy",
      icon: <PrivacyTipIcon/>
    },
    {
      title: "Support",
      path: "/dev/support",
      icon: <HelpIcon/>
    },
    {
      title: "Terms Of Service",
      path: "/dev/termsofservice",
      icon: <GavelIcon/>
    }
  ]

  return (
    <Box sx={{ display: 'flex' }}>

      <CssBaseline />
      <AppBar position="fixed"
      color="default"
      elevation={1}
      sx={{ p:2, 
            zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <img src={CartagonLogo} alt=""/>
          <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1, marginLeft: '.7rem' }}>
            User Substitution App
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <img src={CartagonLogo} alt=""/>
          <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1, marginLeft: '.7rem' }}>
            User Substitution App
          </Typography>
        </Toolbar>
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {drawer_list.map((index) => (
              <ListItem button
              onClick={()=>navigate.push(index.path)}
              key={index.title}>
                <ListItemIcon>
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  {index.icon}
                </ListItemIcon>
                <ListItemText primary={index.title} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
