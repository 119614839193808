import * as React from 'react';
import { useHistory } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import{AppContexProvider, useAppContext} from '../context/AppContext';

import {Elements, useStripe} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//Monday.com
import mondaySdk from "monday-sdk-js";
//import "monday-ui-react-core/dist/main.css"
const monday = mondaySdk();

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://cartagon.com/" target="_blank">
//       www.cartagon.com
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const tiers = [
    {
      title: 'Trial',
      price: '0',
      description: [
        'No restriction on the number of',
        'users registered per account',
        'Only valid for',
        '5 days',
        // 'Help center access',
      ],
      account_users_limit : 10000,
      buttonText: 'Contact Us',
      buttonVariant: 'contained',
      // link: '#',
      stripe_ref: 'trial'
    },
    {
      title: '20 Users',
      // subheader: 'Most popular',
      price: '5',
      description: [
        'Billed Annually',
        'Supports accounts with a maximum of 20 users registered on monday.com',
        // '10 GB of storage',
        // 'Help center access',
        // 'Email support',
      ],
      account_users_limit : 20,
      buttonText: 'Get started',
      buttonVariant: 'contained',
      // link: '/payment',
      stripe_ref: 'price_1KjOfyJ5ZRGV4BpZsmgKmXrF'
    },
    {
      title: '50 Users',
      price: '9',
      description: [
        'Billed Annually',
        'Supports accounts with a maximum of 50 users registered on monday.com',
        // '30 GB of storage',
        // 'Help center access',
        // 'Priority email support',
      ],
      account_users_limit : 50,
      buttonText: 'Get started',
      buttonVariant: 'contained',
      // link: '/payment',
      stripe_ref: 'price_1KjOhBJ5ZRGV4BpZeaQeQwF2'
    },
    {
      title: '100 Users',
      price: '17',
      description: [
        'Billed Annually',
        'Supports accounts with a maximum of 100 users registered on monday.com',
        // '30 GB of storage',
        // 'Help center access',
        // 'Priority email support',
      ],
      account_users_limit : 100,
      buttonText: 'Get started',
      buttonVariant: 'contained',
      // link: '/payment',
      stripe_ref: 'price_1KjOi0J5ZRGV4BpZXRMffVC8'
    },
    {
      title: 'Unlimited',
      price: '25',
      description: [
        'Billed Annually',
        'No restriction on the number of users registered per account',
        // '30 GB of storage',
        // 'Help center access',
        // 'Priority email support',
      ],
      account_users_limit : 10000,
      buttonText: 'Get started',
      buttonVariant: 'contained',
      // link: '/payment',
      stripe_ref: 'price_1KjOiiJ5ZRGV4BpZwMt5s51M'
    },
  ];
  
  const footers = [
    {
      title: 'Legal',
      description: [
        {
          title: 'Privacy policy',
          link: 'dev/privacy/'
        },
        {
          title: 'Terms of service',
          link: 'dev/termsofservice/'
        }
      ],
    },
  ];

function PricingContent(props) {

    const context = useAppContext();

    const stripe = useStripe();

    let navigate = useHistory();

    const goStripeCheckout = async (e, context, stripe_ref, plan_max_users) =>{

        e.preventDefault();

        if (context.accountNumUsers.accountNumUsers > plan_max_users){
          monday.execute("notice", { 
            message: "This plan does not apply to your account, please select Enterprise plan.",
            type: "info", // or "error" (red), or "info" (blue)
            timeout: 5000,
          })
        } else{

            const requestData = {
                method : "POST",
                headers : {"Content-Type":"application/json",
                           "Authorization":context.sessionToken},
                body : JSON.stringify({accountContext : context.accountContext,
                                       stripe_ref})
            }


              const checkoutResponse = await fetch("/backend/stripecheckout/", requestData)
              const checkoutResponseJson = await checkoutResponse.json()

              const sessionId = checkoutResponseJson.id

              if ('error' in checkoutResponseJson){
                navigate.push('/decline', { message: 'Something went wrong.'})
                return null
              } else {
                const {error} = stripe.redirectToCheckout({sessionId})
              }
          }
    }

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing Plans
        </Typography>
        {/* <Typography variant="h5" align="center" color="text.secondary" component="p">
          Quickly build an effective pricing table for your potential customers with
          this layout. It&apos;s built with default MUI components with little
          customization.
        </Typography> */}
      </Container>
      {/* End hero unit */}
      {/* <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end"> */}
      <Container maxWidth="lg" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            // <Grid
            //   item
            //   key={tier.title}
            //   xs={12}
            //   sm={tier.title === 'Enterprise' ? 12 : 6}
            //   md={4}
            // >
            <Grid
              item
              key={tier.title}
              xs={12}
              // sm={tier.title === 'Enterprise' ? 12 : 6}
              sm={6}
              md={2.33}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  // action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                      sx={tier.title === 'Trial' 
                      ? 
                      {display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                      pb: 1} 
                      : 
                      {display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2}}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                    {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>

                  {
                  
                  tier.title === 'Trial' 
                  
                  ?

                  null
                  
                  : 

                  <Button 
                  fullWidth 
                  variant={tier.buttonVariant}
                  // onClick = {(e) => goStripeCheckout(e, context, tier.stripe_ref, tier.account_users_limit)}
                  >
                  {tier.buttonText}
                  </Button>
                  
                  }

                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item.title}>
                    <Link href={item.link} target="_blank" variant="subtitle1" color="text.secondary">
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Footer sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}

export default function Pricing(props) {
    
    const stripePromise = loadStripe('pk_live_gmny4KW9TAY08NlUJhtvRaGC00D3glNaeP');
    
    return(
        <Elements stripe={stripePromise}>
            <AppBar/>
            <AppContexProvider>
              <PricingContent/>
            </AppContexProvider>
        </Elements>
    )
}