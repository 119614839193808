import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Drawer from '../../pages/devSite/Drawer';
import Footer from '../../components/Footer';

export default function Pricing() {

  return (

  <Box sx={{ display: 'flex' }}>
    <CssBaseline/>
    <Drawer/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Toolbar sx={{ flexWrap: 'wrap' }}/>

    {/* <h2>How to use</h2> */}
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h3" component="h1" gutterBottom>
      Pricing
      </Typography>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        Trial
        </Typography>
        <Typography variant="body1">
        • No restriction on the number of users registered per account
        </Typography>

        <Typography variant="body1">
        • Only valid for 5 days
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        20 Users Plan: 5€/mo
        </Typography>
        <Typography variant="body1">
        • Billed Annually
        </Typography>

        <Typography variant="body1">
        • Supports accounts with a maximum of 20 users registered on monday.com
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        50 Users Plan: 9€/mo
        </Typography>
        <Typography variant="body1">
        • Billed Annually
        </Typography>

        <Typography variant="body1">
        • Supports accounts with a maximum of 50 users registered on monday.com
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        100 Users Plan: 17€/mo
        </Typography>
        <Typography variant="body1">
        • Billed Annually
        </Typography>

        <Typography variant="body1">
        • Supports accounts with a maximum of 100 users registered on monday.com
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        Unlimited Plan: 25€/mo
        </Typography>
        <Typography variant="body1">
        • Billed Annually
        </Typography>

        <Typography variant="body1">
        • No restriction on the number of users registered per account
        </Typography>
      </Grid>
    </Box>
    <Footer/>
    </Box>
    </Box>

  );
}