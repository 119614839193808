import React, {useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '../components/CircularProgress';

import{AppContexProvider, useAppContext} from '../context/AppContext';

function AutehticationFunction() {

    let navigate = useHistory();
    const context = useAppContext();

    const [redirect, setRedirect] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {

        // if (!context) return;

        const authValidation = () => {
            // // Enviamos la informacion para el Backend para su validacion
            // const requestData = {
            //     method : "POST",
            //     headers : {"Content-Type":"application/json"},
            //     body : JSON.stringify({
            //         authenticationData : context
            //     })
            //     }
            
            // const authenticationResponse = await fetch("/backend/iniauth/", requestData)
            // const authenticationResponseJson = await authenticationResponse.json()
            // // console.log(authenticationResponseJson)

            if (context.authentication.auth==="False"){
                // console.log('MUST AUTHENTICATE')
                // this.setState({redirect:authenticationResponseJson.auth})

                // const state = JSON.stringify({ 'account_id' : context.accountData.accountId,
                //                                'account_name' : context.accountData.accountName,
                //                                'workspace_id' : context.accountContext.workspaceId,
                //                                'board_id' : context.accountContext.boardId,
                //                                'view_id' : context.accountContext.boardViewId,
                //                                'user_id': context.accountContext.user.id,})

                const state = JSON.stringify(context)

                // console.log('https://auth.monday.com/oauth2/authorize?client_id='+ this.state.context.accountId + '&state=' + state)

                // window.location.replace('https://auth.monday.com/oauth2/authorize?client_id='+ authenticationResponseJson.client_id + '&state=' + state)
                // window.location.href = 'https://auth.monday.com/oauth2/authorize?client_id='+ authenticationResponseJson.client_id + '&state=' + state;

                window.top.location.replace('https://auth.monday.com/oauth2/authorize?client_id='+ context.authentication.client_id + '&state=' + state)
                // window.top.location.href = 'https://auth.monday.com/oauth2/authorize?client_id='+ authenticationResponseJson.client_id + '&state=' + state;

            
            } else {

                if (context.authentication.auth==="Error"){
                    // console.log('LLEGO EL ERROR AL FRONT')
                    setRedirect('Error');
                }

                else{

                    // console.log('CONTEXT Auth:')
                    // console.log(context)
                    
                    if (context.authentication.payment_vigent==="False"){
    
                        if (context.authentication.trial==="False"){
                        
                        // console.log('Vamos a Plans')
    
                        // Si coloco primero a setRedirect('Plans'), entonces no logra cambiar al buttonDisabled
                        // porque automaticamente redirecciona y no permite que se cambie el useState buttonDisabled.
                        setButtonDisabled(true);
                        setRedirect('Plans');
                        
                        } else {
    
                        // console.log('Vamos a App')
                        setRedirect('App')
    
                        }
    
                    } else {
    
                        // console.log('Vamos a App')
                        setRedirect('App')
    
                    }
                }
            }
        }

        // console.log('Antes de revisar el condicional:')

        // console.log(context)

        if ((Object.entries(context.accountContext).length === 0) ||
        (Object.entries(context.authentication).length === 0) ||
        (Object.entries(context.sessionToken).length === 0)){ // IMPORTANTE: Para verificar que un objeto esta vacio, esta es la forma
                                                             // correcta de hacerlo en JavaScript
                                                             // esta forma tambien vale:
                                                             // Object.keys(this.state.errors).length == 0
            return null;

        } else{
            
            authValidation();

        }
    }, [context])

    // const message = () => {
    //     console.log(context) 
    //    }

    if (redirect === 'Plans') {
    // return <Redirect
    //         to={{pathname: '/plans',
    //             accountValues: context,
    //             buttonDisabled: buttonDisabled}}/>

        navigate.push('/plans')
        return null
    }
    
    else if (redirect === 'App'){
    // return <Redirect
    //         to={{pathname: '/app',
    //             buttonDisabled: buttonDisabled}}/>

        navigate.push('/app')
        return null

    }
    else if (redirect === 'Error'){
        // return <Redirect
        //         to={{pathname: '/app',
        //             buttonDisabled: buttonDisabled}}/>
    
        navigate.push('/decline', { message: 'Something went wrong.'})
        return null

        // navigate.push('/plans', { buttonDisabled: buttonDisabled})
        // return null
    
        }
    else{
    return (
    <React.Fragment>
        <CssBaseline/>
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}>
            <CircularProgress/>
        </Grid>   
        </Container>
    </React.Fragment>
    );
    } 
}

export default function Auth() {
    return(
        <AppContexProvider>
            <AutehticationFunction></AutehticationFunction>
        </AppContexProvider>
    )
}
