import * as React from 'react';

import {useState, useEffect} from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuButton from './MenuButton'

import CartagonLogo from '../img/Cartagon_logo_sm.png'

import{AppContexProvider, useAppContext} from '../context/AppContext';


// //Monday.com
// import mondaySdk from "monday-sdk-js";
// //import "monday-ui-react-core/dist/main.css"

// const monday = mondaySdk();

function AppBarFunction(props) {

  const context = useAppContext();

  return (
    <AppBar
    position="static"
    color="default"
    elevation={1}
    sx={{ p:3,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
  >
    <Toolbar sx={{ flexWrap: 'wrap' }}>
    <img src={CartagonLogo} alt=""/>
      <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1, marginLeft: '.7rem' }}>
        User Substitution App
      </Typography>
      <MenuButton
      auth_data = {context.authentication}/> 
    </Toolbar>
  </AppBar>
  );
}


export default function AppBarGeneral(props) {
  return(
      <AppContexProvider>
          <AppBarFunction {...props}></AppBarFunction>
      </AppContexProvider>
  )
}