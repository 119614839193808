// Home.js

import React, { Component } from 'react';

class Home extends Component {

  constructor(props) {
    super(props);

    // Default state
    this.state = {
      settings: {},
      name: "",
      context: {},
    };
}

  render() {
    return (
        <div>
          <h2>Home</h2>
        </div>
    );
  }
}

export default Home;