import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RowRadioButtonsGroup(props) {

  const {handleChange} = props

  return (
    <FormControl component="fieldset">
      <RadioGroup 
      row aria-label="gender" 
      name="row-radio-buttons-group"
      //value={value}
      onChange={handleChange}>
        <FormControlLabel value="white" control={<Radio />} label="Whitelist" />
        <FormControlLabel value="black" control={<Radio />} label="Blacklist" />
        <FormControlLabel value="all" control={<Radio />} label="All Boards" />
      </RadioGroup>
    </FormControl>
  );
}
