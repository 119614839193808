import React, {useState, useEffect, useMemo} from 'react';
import { useHistory } from "react-router-dom";

//Monday.com
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const generalContext = React.createContext();

export function AppContexProvider(props) {

  let navigate = useHistory();

  const [accountContext, setAccountContext] = useState({});
  const [accountNumUsers, setAccountNumUser] = useState({});
  const [authentication, setAuthentication] = useState({});

  const [sessionToken, setSessionToken] = useState("");


  useEffect(()=>{

    const getMondayContext = async () => {

      const mondayContext = await monday.get("context")
      // console.log(mondayContext)
      setAccountContext(mondayContext.data)
 
      const mondaySessionToken = await monday.get("sessionToken")
      // console.log(mondayContext)
      setSessionToken(mondaySessionToken.data)

      const mondayApiQuery2 = await monday.api(`query 
      {users (kind: non_guests) 
        {id name}
      }`)
      setAccountNumUser({accountNumUsers: mondayApiQuery2.data.users.length})
    }

    getMondayContext();

  },[])

  useEffect(()=>{

    const getAuthResponse = async () => {

      // Enviamos la informacion para el Backend para su validacion
      const requestData = {
        method : "POST",
        headers : {"Content-Type":"application/json",
                   "Authorization":sessionToken},
        body : JSON.stringify({
            authenticationData : {accountContext}

        })
        }
    
      const authenticationResponse = await fetch("/backend/iniauth/", requestData)
      const authenticationResponseJson = await authenticationResponse.json()
      
      if (authenticationResponseJson.hasOwnProperty('error')){
        
        if (authenticationResponseJson.error === 'jwt.ExpiredSignatureError'){
          
          navigate.push('/decline', { message: 'Session Expired.'})
        
        } else{
          
          navigate.push('/decline', { message: 'Something went wrong.'})
        
        }
      
      }else{
        
        setAuthentication(authenticationResponseJson)

      }
    }

    if ((Object.entries(accountContext).length === 0) || 
    (Object.entries(sessionToken).length === 0)){

      return null;

    } else{

      getAuthResponse();

    }

  },[accountContext, sessionToken])

  
  const value = useMemo(()=>{
    return({accountContext,
            // accountData,
            accountNumUsers,
            authentication,
            sessionToken})
  },[accountContext, accountNumUsers, authentication, sessionToken])

  return <generalContext.Provider value={value} {...props}/>
}

export function useAppContext(){

  const context = React.useContext(generalContext)

  if (!context){
    throw new Error('useAppContext debe estar dentro del proveedor generalContext')
  }
  return context;
}