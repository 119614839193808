import React, {useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

//Material UI
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import LinearProgress from '@mui/material/LinearProgress';

import AutoMultiSelect from '../components/AutoMultiSelect';
import BigAutoMultiSelect from '../components/BigAutoMultiSelect';

import OpenSelectDialog from '../components/OpenSelectDialog';
import SimpleSelect from '../components/SimpleSelect'
import RadioButton from '../components/RadioButton'
import AppBar from '../components/AppBar';
import PersistentAlert from '../components/PersistentAlert';



//Monday.com
import mondaySdk from "monday-sdk-js";

import{AppContexProvider, useAppContext} from '../context/AppContext';

const monday = mondaySdk();

function ReplaceUserFunction(props) {

    const context = useAppContext();
    let navigate = useHistory();

    // Tuve que traer este parametro desde la anterior vista porque por alguna razon
    // que no me queda clara, no he podido leerlo del "context"
    // const exeUserId = props.location.state.id;

    const [subscribers, setSubscribers] = useState([]);
    const [subscribers2, setSubscribers2] = useState([]);
    const [boards, setBoards] = useState([]);
    const [accountName, setAccountName] = useState("");
    const [exeUserName, setExeUserName] = useState("");
    const [substitutionData, setSubstitutionData] = useState({replaceType:"",
                                                              boardsMultiSelect : []});
    const [errors, setErrors] = useState([]);
    const [formIsValid, setFormIsValid] = useState("");
    const [processing, setProcessing] = useState();
    const [processingRes, setProcessingRes] = useState({});
    const [finalMessage, setFinalMessage] = useState("");


//////////////////////////// Init View /////////////////////////////////////

useEffect(() => {


  const getMondayResponse = async () => {
      
      const subscribersApiQuery = await monday.api(
          `query 
          {
              users (kind: non_guests)
              {
                  id 
                  name
              }
          }`) 

      setSubscribers(subscribersApiQuery.data.users)    

      const boardsList = []
      for (let i = 1; i < 10000; i++) {
        const boardsApiQuery = await monday.api(
          `query 
          {
              boards(limit:200, page:${i})
              {
                  id
                  name
              }
          }`)

          if (boardsApiQuery.data.boards.length){
            boardsList.push.apply(boardsList, boardsApiQuery.data.boards);
          } else{
            break;
          }
      }

      setBoards(boardsList)
          
      const accountNameApiQuery = await monday.api(
          `query
          {
              boards(limit:1)
              {
                  owner
                  {
                      account
                      {
                          slug
                          id
                      }
                  }
              }
          }`)

      setAccountName(accountNameApiQuery.data.boards[0].owner.account.slug)
                    
      // users (ids: [` + exeUserId + `]) 
          
      const exeUserNameApiQuery = await monday.api(
          `query 
          {
              users (ids: [` + context.accountContext.user.id + `]) 
              {
                  name
              }
          }`)

          setExeUserName(exeUserNameApiQuery.data.users[0].name)
      
  }

  if (Object.entries(context.accountContext).length === 0){

    return null;

  } else{

    getMondayResponse();

  }

},[context])


useEffect(() => {

  const getStatusResponse = async () => {
        // Enviamos la informacion para el Backend para su validacion
    
        const requestData = {
            method : "POST",
            headers : {"Content-Type":"application/json",
                       "Authorization":context.sessionToken},
            body : JSON.stringify(context.accountContext)
            }
        
        const processingStatusResponse = await fetch("/backend/processingstatus/", requestData)
        const processingStatusResponseJson = await processingStatusResponse.json()

        if (processingStatusResponseJson.hasOwnProperty('error')){ // Valida si las validaciones del serializer
                                                                   // no son aprobadas.
          if (processingStatusResponseJson.error === 'jwt.ExpiredSignatureError'){

            navigate.push('/decline', { message: 'Session Expired.'})
          } else{
            navigate.push('/decline', { message: 'Something went wrong.'})
          }
        }else{

          setProcessing(processingStatusResponseJson)

        }
    
       
      
      }

  if ((Object.entries(context.accountContext).length === 0) ||
      (Object.entries(context.sessionToken).length === 0)){ 
          return null;

    } else{

      // HE QUEDADO AQUI.. VIENDO COMO HACER QUE SOLO SE EJECUTE LA CONSULTA MIENTRAS EL STATUS ES TRUE:

      // Resolucion:

      // La razon por la que el setInterval() no actualizaba el valor de "processing" para saber que 
      // ya no se estaba procesando una sustitucion, era porque al ser esta verificacion ASINCRONA
      // la respuesta de la funcion no llegaba a tiempo al momento de la verificacion, por lo que 
      // en todo momento la App creia que el valor de "processing" era positivo.

      // La resulucion se obtiene de la siguiente manera:

      // 1.- Se transfiere la validacion del condicional al useEffect como tal (else if) y se
      // agrega como parametro modificador del useEffect a "processing", para que cada vez
      // que se haga la llamada al Backend, este sea actualizado y asi el useEffect pueda
      // verificar su valor con cada iteracion. 

      // 2.- Se crea el setInterval en el condicional "else if", colocando dentro de este
      // la llamada de verificacion del status del backend (getAuthResponse()), de forma tal
      // que se repita cada 5 segundos.

      // 3.- Se coloca el return () => {clearInterval(<nombre del setInterval>);} fuera del
      // setInterval, para que se inicialice con cada iteracion.
      
      // Asi entonces el useEffect verifica el status cada vez que el setInterval itera, evitando
      // que la respuesta asincrona no llegue al condicional que estaba dentro del setInterval
      // anteriormente.

      getStatusResponse();

      if (processing === false){

        return null;
      
      } else if (processing === true){

        const timer = setInterval(() => {
          getStatusResponse()
        }, 5000)
        return () => {clearInterval(timer);}

      }
    }

}, [context, processing])


//////////////////////////// Functions /////////////////////////////////////   

    const onSelectUserToBeReplaced = (event, value) =>{

      let userValue = null
      let subscribersList = []
      if (value!=null) {
          userValue = value
          subscribersList = subscribers.filter((item) => 
          item.id !== value.id)
      }

      setSubstitutionData({...substitutionData,
                            userToBeReplaced: userValue,
                            userSubstitute: null},
                            setSubscribers2(subscribersList))
    }

    const onSelectUserSubstitute = (event, value) =>{
  
      let userValue = null
      if (value!=null) {
        userValue = value
      }
      setSubstitutionData({...substitutionData,
                          userSubstitute: userValue})
      }

    const onChangeReplaceType = (event, value) =>{

      setSubstitutionData({...substitutionData,
                            replaceType: value})
      }

    const handleChange = (event, value) => {

      event.preventDefault();
      
      setSubstitutionData({...substitutionData,
                            boardsMultiSelect: value})
      };

    const handleValidation = () => {
      
      let fields = substitutionData;
      let boardsFields = substitutionData.boardsMultiSelect;
      let errors = [];
      let formIsValid = true;
  
      //userToBeReplaced
      if(!fields["userToBeReplaced"]){
          formIsValid = false;
          errors.push(" User to Replace Cannot be empty");
      }

      //userSubstitute
      if(!fields["userSubstitute"]){
        formIsValid = false;
        errors.push("Substitute User Cannot be empty");
      }

      //replaceType
      if(fields["replaceType"] === ""){
        formIsValid = false;
        errors.push("You must to select a Substitution Type");
      }
    
      if(((fields["replaceType"] === "white")||(fields["replaceType"] === "black")) && (boardsFields.length === 0)){
        formIsValid = false;
        errors.push("You must to select a Substitution Type");
      }
  
      setErrors({errors: errors});
      return formIsValid;
    }


    const onClickReplace = (event) =>{

      event.preventDefault();

      // console.log(formIsValid)

      let newFormIsValid = handleValidation()

      // setFormIsValid(newFormIsValid)
      // () => {

      // console.log(formIsValid)

      if (!newFormIsValid){
          monday.execute("notice", 
          {
            message: "You must fill all information requiered",
            type: "error", // or "error" (red), or "info" (blue)
            timeout: 3000,
          })
        
        } else {
          monday.execute("confirm",
          {
            message: "This process is irreversible. Are you sure to replace the user?",
            confirmButton: "Yes",
            cancelButton: "No",
            excludeCancelButton: false
          }).then((res) => {

            if (res.data.confirm === true){
                            
              monday.execute("notice", 
              { 
                message: "Processing.",
                type: "info", // or "error" (red), or "info" (blue)
                timeout: 7000,
              })
              postSetProcessing();
              executeSubstitution();

            } else {
              return;
            }
          })
        }
    }

// Fue necesario crear una funcion que actualizara el status de la sustitucion justo antes
// de que esta fuera enviada al backend, ya que, si no se realizaba este proceso previo al
// setProcessing(true), este se ejecutaba, pero antes de que la sustitucion comenzara su ejecucion
// en el backend, por lo que la asincronia de los procesos hacia que el useEffect volviera
// a ejecutar el setProcessing(false) al recibir el valor "Done" de la BBDD, es decir, antes de 
// que en la BBDD se actualizara el status del proceso a "Processing" por lo que en el front no 
// persitia el estado processing = true

    const postSetProcessing = async () => {
      
      const requestOptions = {
        method : "POST",
        headers : {"Content-Type":"application/json",
                   "Authorization":context.sessionToken},
        body : JSON.stringify(context.accountContext)
      };
      
      const response = await fetch("/backend/setprocessing/", requestOptions);
      const responseJson = await response.json()

      if (responseJson.hasOwnProperty('error')){ // Valida si las validaciones del serializer
                                                                 // no son aprobadas.
        navigate.push('/decline', { message: 'Something went wrong.'})
      
      }else{
        
        setProcessing(true)
      
      }
      
    }


    const executeSubstitution = async () => {

      const requestOptions = {
        method : "POST",
        headers : {"Content-Type":"application/json",
                   "Authorization":context.sessionToken},
        body : JSON.stringify({substitution_data : substitutionData,
                               accountContext : context.accountContext})
      };
      
      const response = await fetch("/backend/usersubstitution/", requestOptions);
      const responseJson = await response.json()

      if (responseJson.message==='Done') {

        monday.execute("notice", {
          //message : responseJson.message,
          message: "The replacement has been completed",
          type: "info", // or "error" (red), or "info" (blue)
          timeout: 15000,
           })

      } else{
          
        monday.execute("notice", {
          //message : responseJson.message,
          message: "Something went wrong",
          type: "error", // or "error" (red), or "info" (blue)
          timeout: 7000,
           })
      }
          
    }

    const isWhiteOrBlackType = substitutionData.replaceType
    let select_boards
    if ((isWhiteOrBlackType ==="all")||(isWhiteOrBlackType ==="")) {
    select_boards = <Grid item p={2}/>
    } 
    else {
      select_boards = <OpenSelectDialog
                       component = {<BigAutoMultiSelect
                                    boards = {boards}
                                    handleChange = {handleChange}
                                    // filterOptions = {filterOptions}
                                    boardsSelected = {substitutionData.boardsMultiSelect}>
                                    </BigAutoMultiSelect>}
                       labelMessage = "Click to Select the Boards"
                       titleMessage = "Boards Selection">
                      </OpenSelectDialog>
      }

    return (
        <React.Fragment>
        {((context.authentication.trial === 'True') && (context.authentication.payment_vigent === 'False')) ? <PersistentAlert/> : <div/>}
        {processing === true ? <LinearProgress/> : <div/>}
        {processing === true ? <Dialog open={true}/> : <div/>}
        {}
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}/>
        <CssBaseline/>
        {/* Hero unit */}
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
          {/* <button 
          onClick={()=>{setProcessing(true)}}>
            Set True
          </button>
          <button 
          onClick={()=>{setProcessing(false)}}>
            Set False
          </button> */}
          {/* <button 
          onClick={()=>{console.log(context)}}>
            Auth
          </button> */}
                      
          <Paper 
          sx={{ p: 2,
          backgroundColor: '#f5f5f5'}}                     
          elevation = '24'>

            <Grid container
            direction="column"
            alignItems="center"
            justifyContent="center">

              <Grid item p={2}>
                <Typography align="center" variant="h6">
                Substitute Users
                </Typography>
              </Grid>

              <Grid item p={1}>
                <SimpleSelect
                boards = {subscribers}
                onChange = {onSelectUserToBeReplaced}
                user = {substitutionData.userToBeReplaced}
                label = "User To Replace"/>
              </Grid>

              <Grid item p={1}>
                <SimpleSelect
                boards = {subscribers2}
                onChange = {onSelectUserSubstitute}
                user = {substitutionData.userSubstitute}
                label = "Substitute User"/>
              </Grid>

              <Grid item p={1}>
                <Typography align="center" variant="h6">
                Substitution Type
                </Typography>
              </Grid>

              <Grid item p={1}>
                <RadioButton
                handleChange = {onChangeReplaceType}/>
              </Grid>

              <Grid item p={1}>
              {select_boards}
              </Grid>

              <Grid item p={2}>
                <Button 
                variant="contained"
                size="large"
                onClick={onClickReplace}>
                  Replace User
                </Button>
              </Grid>
            </Grid>
          </Paper> 
        </Container>
        {/* </Dialog> */}
        </React.Fragment>
    );
}

export default function ReplaceUserApp(props) {

    // const {buttonDisabled} = props.location.state;

    return(
        <React.Fragment>
            <AppBar/>
            <AppContexProvider>
                <ReplaceUserFunction {...props}></ReplaceUserFunction>
            </AppContexProvider>
        </React.Fragment>
    )
}