import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Drawer from '../../pages/devSite/Drawer';
import Footer from '../../components/Footer';

import AppForm from '../../img/App_Form.png';

export default function Privacy() {

  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline/>
      <Drawer/>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar sx={{ flexWrap: 'wrap' }}/>
  
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy
        </Typography>
  
        <Grid item p={3}>
          <Typography variant="body1">
          This application does not store any data related to users, boards, 
          items or any other information related to the monday.com account, 
          beyond the Ids necessary to control the use of the App.
          </Typography>
        </Grid>

      </Box>
      <Footer/>
      </Box>
      </Box>
    );
}
