import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';

function renderRow(props) {
  const { index, style, data} = props;



  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton onClick = {() =>{window.open(
  'https://'+data.accountName+'.monday.com/boards/'+data.customData[index].id,
  '_blank' // <- This is what makes it open in a new window.
);}}>
{/* <ListItemButton onClick = {}> */}
        <ListItemText primary={data.customData[index].name} />
      </ListItemButton>
    </ListItem>
  );
}

export default function VirtualizedList(props) {
  const {boards, accountName} = props
  return (
    <Box
      sx={{ width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}
    >
      <FixedSizeList
        height={400}
        width={360}
        itemSize={46}
        itemCount={boards.length}
        overscanCount={5}
        itemData={{customData: boards, accountName : accountName}}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
}