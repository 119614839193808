import React, {useState, useEffect } from "react";

//Material UI
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import Stack from '@mui/material/Stack';

import AppBar from '../components/AppBar';
import OpenSelectDialog from '../components/OpenSelectDialog';
import ListInterBoards from '../components/ListInterBoards';
import CircularProgress from '../components/CircularProgress';


import{AppContexProvider, useAppContext} from '../context/AppContext';

// const monday = mondaySdk();

function SummaryFunction(props) {

    const context = useAppContext();
    
    const [rows, setRows] = useState("");
    const [accountName, setAccountName] = useState("");
    const [loading, setLoading] = useState(true);

    // Tuve que traer este parametro desde la anterior vista porque por alguna razon
    // que no me queda clara, no he podido leerlo del "context"
    // const exeUserId = props.location.state.id;

     useEffect(() => {
       
      async function getSummaryData() {

        const requestData = {
              method : "POST",
              headers : {"Content-Type":"application/json",
                         "Authorization":context.sessionToken},
              body : JSON.stringify(context.accountContext)
            }
      
            const summaryDataResponse = await fetch("/backend/summarydata/", requestData)
            const summaryDataResponseJson = await summaryDataResponse.json()
            

            if (summaryDataResponseJson.message === 'No Rows'){

              setLoading(false)
              return null;
            
            }else{

              setRows(summaryDataResponseJson.substitution_list);
              setAccountName(summaryDataResponseJson.account_name)
              setLoading(false)
              
            }
          }
          
          if ((Object.entries(context.accountContext).length === 0) ||
              (Object.entries(context.sessionToken).length === 0)){ 
          return null;
        } else{
          //  console.log(context);
           getSummaryData();
          //  Object.keys(rows).map(item => {
          //    console.log(rows[item].intervened_board)
          //  })
          
          }
    },[context]);


  const columns = [
    {
      field: "summary_data_date",
      headerName: "Date",
      width: 130
    },
    {
      field: "time",
      headerName: "Time",
      width: 130
    },
    {
      field: "user_executor",
      valueFormatter: ({ value }) => value.name,
      headerName: "Executor User",
      width: 170
    },
    {
      field: "user_out_id",
      valueFormatter: ({ value }) => value.name,
      headerName: "User Out",
      width: 170
    },
    {
      field: "user_in_id",
      valueFormatter: ({ value }) => value.name,
      headerName: "User In",
      width: 170
    },
    {
      field: "num_items_intervened",
      headerName: "# Items Intervened",
      width: 150
    },
    {
      field: "num_substitutions",
      headerName: "# Substitutions",
      width: 140
    },
    {
      field: "Boards Intervened",
      renderCell: (cellValues) => {
        return (

        <OpenSelectDialog
        component = {<ListInterBoards
                      boards = {cellValues.row.intervened_board}
                      accountName = {accountName}>
                     </ListInterBoards>}
          labelMessage = "Open list of boards"
          titleMessage = "List of boards intervened">
        </OpenSelectDialog>
        );
      },
      width: 200
    },
    {
      field: "execution_duration",
      headerName: "Execution Duration",
      width: 160
    },

  ];


    // const handleClick = (event, values) => {
    //   // console.log(values);
    // };
    
    const handleCellClick = (param, event) => {
      event.stopPropagation();
    };
    
    const handleRowClick = (param, event) => {
      event.stopPropagation();
    };

    function LoadingOverlay() {
      return (
        <Stack height="100%" alignItems="center" justifyContent="center">
          <CircularProgress/>
        </Stack> 
      );
    }

    function NoRowsOverlay() {
      return (
        <Stack height="100%" alignItems="center" justifyContent="center">
          No substitutions have been made yet.
        </Stack> 
      );
    }


  //////////////////////////////////////////////////////////////////////////////


      return (
          <React.Fragment>
          <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}/>
          <CssBaseline/>
          <Container disableGutters maxWidth='xl' component="main" sx={{ pt: 8, pb: 6 }}>              
            <Paper 
            sx={{ p: 2,
            backgroundColor: '#f5f5f5'}}                     
            elevation = '24'>

              <Grid container
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: 435, width: "100%" }}>
                    {/* <Grid item p={2}>
                      <Typography align="center" variant="h6">
                        Summary Data
                      </Typography>
                    </Grid> */}
                  <DataGrid rowHeight={60}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            loading={loading}
                            components={{LoadingOverlay, NoRowsOverlay}}
                            onCellClick={handleCellClick}
                            onRowClick={handleRowClick}/>
              </Grid>
            </Paper> 
          </Container>
          </React.Fragment>
      );
}

export default function Summary(props) {

    return(
        <React.Fragment>
            <AppBar/>
            <AppContexProvider>
                <SummaryFunction {...props}></SummaryFunction>
            </AppContexProvider>
        </React.Fragment>
    )
}