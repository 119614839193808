import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import Link from '@mui/material/Link';

import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

export default function BasicMenu(props) {

  const {auth_data} = props;

  const [vigent, setVigent] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleCloseTest = () => {
  //   console.log(auth_data)
  // };

  const handleNoValid = () => {
    setAnchorEl(null);
    monday.execute("notice", {
      message: "The use of the App has expired",
      type: "info", // or "error" (red), or "info" (blue)
      timeout: 5000,
       })
  };

  const styles = {
    button: {
        width: 70, height: 70,
        padding: 0
    },
    icon: {
        fontSize:90,
        color:'#fffff'
    },
    tooltip: {
        marginLeft:7
    }
};

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton aria-label="delete" size="large" onClick={handleClick} style={styles.button} iconStyle={styles.icon} tooltipStyles={styles.tooltip}>
              <MenuIcon sx={{ fontSize: 50 }} />
          </IconButton>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {
        
        (auth_data.trial === 'False') && (auth_data.payment_vigent === 'False') 
        
        ?

        <MenuItem href="javascript:void(0)" component={Link} onClick={handleNoValid}>APP</MenuItem>
        
        : 
        <MenuItem href="/app" component={Link}>APP</MenuItem>
        }
        {/* <MenuItem href={vigent === true ? "/app" : "javascript:void(0)"} component={Link}>APP</MenuItem> */}
        
        <MenuItem href="/summary" component={Link} onClick={handleClose}>Summary</MenuItem>
        <MenuItem href="/plans" component={Link} onClick={handleClose}>Plans</MenuItem>
        <MenuItem href="/dev/support" target="_blank" component={Link} onClick={handleClose}>Support</MenuItem>
        {/* <MenuItem onClick={handleCloseTest}>Test Auth Data</MenuItem> */}
      </Menu>
    </div>
  );
}
