import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Drawer from '../../pages/devSite/Drawer';
import Footer from '../../components/Footer';

import AppForm from '../../img/App_Form.png';

export default function Introduction() {

  return (

  <Box sx={{ display: 'flex' }}>
    <CssBaseline/>
    <Drawer/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Toolbar sx={{ flexWrap: 'wrap' }}/>

    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Introduction
      </Typography>

      <Grid item p={3}>
        <Typography variant="body1">
        Replace users on items on different boards at the same time, 
        saving time and resources on manual replacements. The User 
        Substitution App provides the ability to perform the user 
        replacement task in people columns in a systematic manner, 
        allowing the operator to simplify the task management workflow.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Features
        </Typography>

        <Typography variant="body1">
          •	Substitution of users in people type columns on several 
          boards to which the operator has access at the same time.
        </Typography>

        <Typography variant="body1">
          •	Allows the selection of boards according to the needs 
          of the operator through a whitelist.
        </Typography>

        <Typography variant="body1">
          •	Allows you to select the boards that you do not want to do 
          a substitution, when it is necessary to replace a user in the 
          entire account, except for some boards, this using a blacklist.
        </Typography>

        <Typography variant="body1">
          •	Allows a user to be replaced in all the account boards.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="body1">
        A subscription within a monday.com account covers unlimited usage 
        and users, and the User Substitution App can be added to an unlimited 
        number of boards. All account members can access all features and 
        use of the User Substitution App.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="body1">
        The application has a free trial of 5 days. After that, there are 
        certain plans based on the number of users on each account.
        </Typography>
      </Grid>
    </Box>
    <Footer/>
    </Box>
    </Box>
  );
}
