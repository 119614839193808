import React, { useEffect } from "react";
import { useParams} from 'react-router-dom'

//Monday.com
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

function DeclineContent(props) {

  // let { message } = useParams();
  const {message} = props;

  useEffect(() => {
    
    async function getMondayContext() {
        
        // Obtenermos la informacion del Usuario que esta trabajando en Monday
        const mondayContext = await monday.get("context")
        const boardId = mondayContext.data.boardId

        // Obtenermos la informacion de la cuenta del Usuario que esta trabajando en Monday
        const mondayApiQuery = await monday.api(`query 
        {boards(limit:1) 
        {owner
            {account {slug id}
            }
        }
        }`)
        const boardName = mondayApiQuery.data.boards[0].owner.account.slug

        console.log(message)

        window.top.location.replace("https://" + boardName + ".monday.com/boards/" + boardId)
        
    }
    monday.execute("notice", { 
      message: message,
      type: "error", // or "error" (red), or "info" (blue)
      timeout: 7000,
    })

    setTimeout( () => {
      getMondayContext()
    }, 7000)
    
  },[])

return (null);
}

export default function Decline(props) {

  const {message} = props.location.state;
  
  return(
  <DeclineContent message={message}/>
  )
}