import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox(props) {

  const {boards, onChange, user, label} = props;

  // Aunque el siguiente codigo parece ambiguo, no se resetea al componente
  // sin el, pues no parece tomar el valor "null" desde la pagina que invoque el valor
  let validatorNull = null

  
  if (user != null){
    validatorNull = user
  }
    
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={boards}
      getOptionLabel={(option) => option.name}
      autoSelect = {true}
      value={validatorNull}
      onChange = {onChange}
      sx={{ width: 300, backgroundColor: "#fafafa"}}
      renderInput={(params) => <TextField {...params} label={label}/>}
    />
  );
}

