import * as React from 'react';
import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cartagon.com/" target="_blank">
      www.cartagon.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  
  const { status, session_id } = useParams();

  const [paymentMessage, setPaymentMessage] = useState('');
  const [appUrl, setAppUrl] = useState({});

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

  useEffect(() => {

    const getStripeSession = async () => {

    // async function getStripeSession() {

      const csrftoken = getCookie('csrftoken');

      const requestData = {
        method : "POST",
        headers : {"Content-Type":"application/json",
                   "X-CSRFToken": csrftoken},
        body : JSON.stringify({
          sessionId : session_id})
      }
        
      const stripeSessionResponse = await fetch("/backend/getstripesession/", requestData)
      const stripeSessionResponseJson = await stripeSessionResponse.json()
      setAppUrl(stripeSessionResponseJson.metadata.app_url)
    }

    getStripeSession()

    if (Object.entries(appUrl).length === 0){
      return null;
    
    } else{

      if (status === 'success'){

        setPaymentMessage("Your payment was successful")
  
      }else{

        setPaymentMessage("Something was wrong with your payment")
  
  
      }
    
    }

  },[appUrl]);


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#33eb91' }}>
            <CheckCircleIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            {paymentMessage}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              href={appUrl}>
              Go to App
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}