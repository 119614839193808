import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Drawer from '../../pages/devSite/Drawer';
import Footer from '../../components/Footer';

import AppForm from '../../img/App_Form.png';

export default function HowToUse() {

  return (

  <Box sx={{ display: 'flex' }}>
    <CssBaseline/>
    <Drawer/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Toolbar sx={{ flexWrap: 'wrap' }}/>

    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h3" component="h1" gutterBottom>
      How to use
      </Typography>
      
      <Grid item p={3}
      justify ="center"
      align ="center"
      sx={{ mt: 5 }}>
        <img src={AppForm} alt="" height={271} width={329}/>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 1
        </Typography>
        <Typography variant="body1">
          We select the user we want to replace.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 2
        </Typography>
        <Typography variant="body1">
          We select the user who will replace the outgoing user.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 3
        </Typography>
        <Typography variant="body1">
          We select the type of substitution based on the boards:
        </Typography>

        <Typography variant="body1">
          •	Whitelist: The substitution will be made only on the boards of the account that we select
        </Typography>

        <Typography variant="body1">
          •	Blacklist: The substitution will be made on all account boards except those we select.
        </Typography>

        <Typography variant="body1">
          •	All boards: Substitution will be made on all boards of the account.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 4
        </Typography>
        <Typography variant="body1">
          In case the type of substitution is:
        </Typography>

        <Typography variant="body1">
          •	Whitelist
        </Typography>

        <Typography variant="body1">
          •	Blacklist
        </Typography>

        <Typography variant="body1">
          We press the button to select the boards and select them.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 5
        </Typography>
        <Typography variant="body1">
          Press the "Replace User" button.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 6
        </Typography>
        <Typography variant="body1">
          We confirm the user's replacement process.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 7
        </Typography>
        <Typography variant="body1">
          We look forward to the replacement.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Step 8
        </Typography>
        <Typography variant="body1">
          We can check the result of the substitution process on the summary board.
        </Typography>
      </Grid>
    </Box>
    <Footer/>
    </Box>
    </Box>
  );
}
