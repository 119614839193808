import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Drawer from '../../pages/devSite/Drawer';
import Footer from '../../components/Footer';

export default function Support() {

  return (

  <Box sx={{ display: 'flex' }}>
    <CssBaseline/>
    <Drawer/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Toolbar sx={{ flexWrap: 'wrap' }}/>

    {/* <h2>How to use</h2> */}
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h3" component="h1" gutterBottom>
      Support
      </Typography>

      <Grid item p={3}>
        <Typography variant="body1">
        Contact us by email: mondaysupport@cartagon.com
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="body1">
        Cartagon Apps generally responds within 24 hours to new 
        support requests and updates at least every 48 hours for 
        existing support requests. However, this could be faster.
        </Typography>
      </Grid>
    </Box>
    <Footer/>
    </Box>
    </Box>

  );
}
