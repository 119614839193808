import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Drawer from '../../pages/devSite/Drawer';
import Footer from '../../components/Footer';

export default function Installation() {

  return (

  <Box sx={{ display: 'flex' }}>
    <CssBaseline/>
    <Drawer/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Toolbar sx={{ flexWrap: 'wrap' }}/>

    {/* <h2>How to use</h2> */}
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h3" component="h1" gutterBottom>
      Installation
      </Typography>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        Step 1
        </Typography>
        <Typography variant="body1">
        Install the User Substitution App from monday.com Apps Marketplace.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        Step 2
        </Typography>
        <Typography variant="body1">
        Once installed, you can add it by clicking on any board on the side menú:
        </Typography>

        <Typography variant="body1">
        •	Click "Add View" button.
        </Typography>

        <Typography variant="body1">
        •	Click "Apps" button.
        </Typography>

        <Typography variant="body1">
        •	Search for User Substitution App and click "Open in board".
      </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        Step 3
        </Typography>
        <Typography variant="body1">
        Authorize the use of the App for the user.
        </Typography>
      </Grid>

      <Grid item p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
        Step 4
        </Typography>
        <Typography variant="body1">
        Use the App.
        </Typography>
      </Grid>
    </Box>
    <Footer/>
    </Box>
    </Box>

  );
}
