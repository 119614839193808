import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
// import About from './pages/About';
// import Authentication from './pages/Authentication';
import Authentication from './pages/AuthenticationFunction';
import DeclineApp from './pages/DeclineApp';
import AppPlansFunction from './pages/AppPlansFunction';
import PaymentResponse from './pages/PaymentResponse';
// import Dashboard from './pages/devSite/Dashboard';
// import Test from './pages/devSite/Test';
import Introduction from './pages/devSite/Introduction';
import Installation from './pages/devSite/Installation';
import HowToUse from './pages/devSite/HowToUse';
import Pricing from './pages/devSite/Pricing';
import Privacy from './pages/devSite/Privacy';
import Support from './pages/devSite/Support';
import TermsOfService from './pages/devSite/TermsOfService';

// import ReplaceUserApp from './pages/ReplaceUserApp';
import AppFunction from './pages/AppFunction';
import Summary from './pages/Summary';

function App() {
  return (
    <React.Fragment>
      <Router>
      {/* <AppBar/> */}
        <Switch>
          <Route exact path='/' component={Home} />
          {/* <Route path='/about' component={About} /> */}
          <Route exact path='/authentication' component={Authentication} />
          {/* <Route exact path='/decline/:message' component={DeclineApp} /> */}
          <Route exact path='/decline' component={DeclineApp} />
          <Route exact path='/plans' component={AppPlansFunction} />
          <Route exact path='/payment/:status/:session_id' component={PaymentResponse} />
          {/* <Route exact path='/dev' component={Test} /> */}
          <Route exact path='/dev/introduction' component={Introduction} />
          <Route exact path='/dev/installation' component={Installation} />
          <Route exact path='/dev/howtouse' component={HowToUse} />
          <Route exact path='/dev/pricing' component={Pricing} />
          <Route exact path='/dev/privacy' component={Privacy} />
          <Route exact path='/dev/support' component={Support} />
          <Route exact path='/dev/termsofservice' component={TermsOfService} />
          {/* <Route path='/payment/:session_id' component={PaymentSuccess} /> */}
          <Route exact path='/app' component={AppFunction} />
          <Route exact path='/summary' component={Summary} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
